@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  overflow-y: scroll;
}

.snackbar-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#snackbar-fixed-container {
  position: fixed;
  bottom: 4rem;
}